import React, { useState } from 'react';
import patternLines from '../images/patternLines.png'; // Replace with the correct path for your pattern

const HailRiskBenefits = () => {
  // Define states for each expandable section
  const [expandedSection, setExpandedSection] = useState(null);

  // Function to toggle a section
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div
      className="relative py-12 w-full text-center min-h-128 justify-center flex items-center font-poppins fading-border"
      style={{
        background: 'linear-gradient(to bottom, white, #F5F3EA)', // Updated gradient direction
      }}
    >
      {/* Decorative Pattern - top-left */}
      <div className="absolute top-0 left-0 w-72 transform rotate-0 hidden md:block">
        <img src={patternLines} alt="Pattern" className="w-full h-full object-contain" />
      </div>
      {/* Decorative Pattern - top-right */}
      <div className="absolute top-0 right-0 w-72 transform rotate-180 hidden md:block">
        <img src={patternLines} alt="Pattern" className="w-full h-full object-contain" />
      </div>
      {/* Decorative Pattern - bottom-left */}
      <div className="absolute bottom-0 left-0 w-72  transform rotate-180 hidden md:block">
        <img src={patternLines} alt="Pattern" className="w-full h-full object-contain" />
      </div>
      {/* Decorative Pattern - bottom-right */}
      <div className="absolute bottom-0 right-0 w-72 transform rotate-270 hidden md:block">
        <img src={patternLines} alt="Pattern" className="w-full h-full object-contain" />
      </div>

      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl text-vayuh font-normal text-gray-800">
            Why Choose Vayuh AI's Hail Risk Model?
          </h2>
        </div>

        <div className="text-center mb-8">
          <h3 className="text-xl font-light text-gray-800">
            Key Benefits at a Glance
          </h3>
        </div>

        {/* Benefits Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center max-w-3xl mx-auto px-4">
          {/* Left Column */}
          <div className="space-y-6 text-center mx-auto">
            {/* Probabilistic View of Historical Risk */}
            <div className="flex flex-col items-start max-w-xs sm:max-w-2xl md:w-96">
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => toggleSection('probabilistic')}
              >
                <span
                  className={`text-3xl text-gray-600 transform transition-transform duration-300 ${
                    expandedSection === 'probabilistic' ? 'rotate-90' : ''
                  } mr-4`}
                >
                  ➤
                </span>
                <h4 className="text-xl font-normal text-left max-w-xs">
                  Probabilistic View of Historical Risk
                </h4>
              </div>
              {expandedSection === 'probabilistic' && (
                <p className="text-gray-600 mt-2 text-left">
                  Even accurately recorded historical events are only one realization of the probability of a hail event occurring in that region. A probabilistic view of history is more useful for determining exposure strategy, pricing, and underwriting.
                </p>
              )}
            </div>

            {/* Nuanced Data */}
            <div className="flex flex-col items-start max-w-xs sm:max-w-2xl  md:w-96">
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => toggleSection('nuanced')}
              >
                <span
                  className={`text-3xl text-gray-600 transform transition-transform duration-300 ${
                    expandedSection === 'nuanced' ? 'rotate-90' : ''
                  } mr-4`}
                >
                  ➤
                </span>
                <h4 className="text-xl font-normal">Nuanced Data</h4>
              </div>
              {expandedSection === 'nuanced' && (
                <p className="text-gray-600 mt-2 text-left">
                  Probability data for different hail sizes. Frequency data also available for every location.
                </p>
              )}
            </div>

            {/* Global Risk Coverage */}
            <div className="flex flex-col items-start max-w-xs sm:max-w-2xl  md:w-96">
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => toggleSection('global')}
              >
                <span
                  className={`text-3xl text-gray-600 transform transition-transform duration-300 ${
                    expandedSection === 'global' ? 'rotate-90' : ''
                  } mr-4`}
                >
                  ➤
                </span>
                <h4 className="text-xl font-normal">Global Risk Coverage</h4>
              </div>
              {expandedSection === 'global' && (
                <p className="text-gray-600 mt-2 text-left">
                  In regions where very little or no historical data, Vayuh's AI-hail risk model outputs both historical and future-looking hail probability data.
                </p>
              )}
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6 text-center mx-auto">
            {/* High-Resolution & Speed */}
            <div className="flex flex-col items-start max-w-xs sm:max-w-2xl  md:w-96">
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => toggleSection('resolution')}
              >
                <span
                  className={`text-3xl text-gray-600 transform transition-transform duration-300 ${
                    expandedSection === 'resolution' ? 'rotate-90' : ''
                  } mr-4`}
                >
                  ➤
                </span>
                <h4 className="text-xl font-normal text-left max-w-xs">Backward-looking transient view</h4>
              </div>
              {expandedSection === 'resolution' && (
                <p className="text-gray-600 mt-2 text-left">
                  Helps you see how risk has changed historically, year over year, in any specific locations
                </p>
              )}
            </div>

            {/* Fast & Scalable */}
            <div className="flex flex-col items-start max-w-xs sm:max-w-2xl  md:w-96">
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => toggleSection('scalable')}
              >
                <span
                  className={`text-3xl text-gray-600 transform transition-transform duration-300 ${
                    expandedSection === 'scalable' ? 'rotate-90' : ''
                  } mr-4`}
                >
                  ➤
                </span>
                <h4 className="text-xl font-normal text-left max-w-xs">New inferences and scenario planning</h4>
              </div>
              {expandedSection === 'scalable' && (
                <p className="text-gray-600 mt-2 text-left">
                  Our models are 100x faster than traditional models, enabling you to do stochastic modeling and simulations on demand.
                </p>
              )}
            </div>

            {/* Backward & Forward-Looking Insights */}
            <div className="flex flex-col items-start max-w-xs sm:max-w-2xl  md:w-96">
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => toggleSection('insights')}
              >
                <span
                  className={`text-3xl text-gray-600 transform transition-transform duration-300 ${
                    expandedSection === 'insights' ? 'rotate-90' : ''
                  } mr-4`}
                >
                  ➤
                </span>
                <h4 className="text-xl font-normal text-left">
                  Forward-looking view
                </h4>
              </div>
              {expandedSection === 'insights' && (
                <p className="text-gray-600 mt-2 text-left">
                  Understanding future hail risk at a specific location for the next few years gives your risk strategy better confidence.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HailRiskBenefits;
