import React from 'react';
import section11 from '../images/section11.png';
import section12 from '../images/section12.png';
import section13 from '../images/section13.png';
import pattern1 from '../images/pattern1.png'; // Add the pattern image
import arrowIcon from '../images/arrow-icon.png'; // For the arrow icon in the middle

const HailRiskProblems = () => {
  return (
    <div
      className="relative py-12 w-full"
      style={{
        background: 'linear-gradient(to bottom, #F5F3EA, white)',
      }}
    >
      {/* Pattern on the left side */}
      <div
        className="absolute left-0 top-0 h-3/5 w-1/2"
        style={{
          backgroundImage: `url(${pattern1})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'left top',
        }}
      />

      {/* Container with a fading border bottom */}
      <div className="container mx-auto px-4 relative z-10 fading-border">
        <div className="text-center mb-8"></div>

        <div className="grid grid-cols-1 md:grid-cols-[45%,10%,45%] gap-4 items-center max-w-3xl mx-auto">
          {/* Left side with text */}
          <div className="text-gray-800 text-left space-y-8 relative z-20">
            <h2 className="text-3xl text-vayuh text-center md:text-left md:text-4xl font-normal text-gray-800 font-poppins">
              The Problem with Traditional Hail Risk Models
            </h2>
          </div>

          {/* Middle section with arrow icon */}
          <div className="flex justify-center relative z-20">
            <img src={arrowIcon} alt="Arrow" className="w-28" />
          </div>

          {/* Right side with icons */}
          <div className="text-gray-800 text-left space-y-8 relative z-20">
            <div className="flex items-start">
              <img src={section11} alt="Icon" className="w-12 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Inaccurate Predictions:</h3>
                <p>Underestimating or overestimating hail severity exposes insurers to losses or premium hikes.</p>
              </div>
            </div>
            <div className="flex items-start">
              <img src={section12} alt="Icon" className="w-12 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Limited Insights:</h3>
                <p>Lack of accuracy and granularity, with no future view of risk.</p>
              </div>
            </div>
            <div className="flex items-start">
              <img src={section13} alt="Icon" className="w-12 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Slow & Resource Intensive:</h3>
                <p>Current methods are slow and expensive.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HailRiskProblems;
