import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import CalendlyEmbed from "../components/CalendalyEmbed"
import BlueHeader from "../components/BlueHeader"
import Footer from "../components/Footer";
import Workshop from "../components/Workshop";
import WorkshopForm from "../components/WorkshopForm";
import HeroSection from "../components/HeroSection";
import HailRiskProblems from "../components/HailRiskProblems";
import HailRiskBenefits from "../components/HailRiskBenefits";
import WhyTraditionalModelsFail from "../components/WhyTraditionalModelsFail";
import OurApproach from "../components/OurApproach";
import DemoSection from "../components/DemoSection";
import ExposureDataSection from "../components/ExposureDataSection";
import DemoForm from "../components/DemoForm";

const RegisterPage = () => {

  const [isOpen, setIsOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsOpen(true);

  // Function to close the modal
  const closeModal = () => setIsOpen(false);

  return (<Layout demoForm={openModal}>
    <SEO title="Home" />
    <DemoForm isOpen={isOpen} closeModal={closeModal}/>
    <div className="flex overflow-x-hidden flex-col items-center main-content">
      <HeroSection demoForm={openModal}/>
      <HailRiskProblems />
      <HailRiskBenefits />
      <WhyTraditionalModelsFail />
      <OurApproach />
      <ExposureDataSection />
      <DemoSection demoForm={openModal}/>
      {/* <Workshop /> */}
      <div className="flex"></div>
      <div className="w-full nav-line-2"></div>
      <Footer />
    </div>

  </Layout>)
}

export default RegisterPage