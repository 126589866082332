import React from 'react';
import hero from '../images/hero.jpg';

const HeroSection = ({demoForm}) => {
  return (
    <div className="relative">
      {/* Background image */}
      <div className="relative h-[60vh] w-screen bg-cover bg-center" style={{ backgroundImage: `url(${hero})` }}>
        <div className="absolute inset-0 bg-black opacity-75"></div>

        {/* Content */}
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center px-4 md:px-16 lg:px-24">
          <h1 className="text-white text-3xl md:text-5xl font-poppins font-medium mb-4">
            <span className='md:text-3xl font-light'>Revolutionizing </span> <br /> SCS Risk Management<br/> <span className='md:text-3xl'>with Vayuh AI</span>
          </h1>
          <p className="text-white text-lg md:text-xl font-poppins font-light mb-12">
            Hailstorms Don't Have to Be a Headache.
          </p>
          {/* Button with Shine Effect */}
          <div className="flex justify-center items-center">
                <a
                  href="/schedule-demo"
                  className="relative text-black py-3 px-6 shadow-lg transition duration-300 font-poppins font-normal overflow-hidden shine-button"
                  style={{
                    background: 'linear-gradient(to right, #FFE693, #F0C22F)',
                    borderRadius: '6px',
                  }}
                  onClick={(event)=> {event.preventDefault(); demoForm();}}
                >
                  Schedule a Demo
                </a>
              </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
