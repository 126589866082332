import React from 'react';
import centerIcon from '../images/center-icon.png'; // Replace with the actual center icon path
import centerIconMobile from '../images/center-icon-mobile.png';
import card1Icon from '../images/card1-background.jpg'; // Replace with the actual card background images
import card2Icon from '../images/card2-background.jpg';
import card3Icon from '../images/card3-background.jpg';
import card4Icon from '../images/card4-background.jpg';
import card5Icon from '../images/card5-background.jpg';
import card1TopIcon from '../images/card1-icon.png'; // Replace with top icon images
import card2TopIcon from '../images/card2-icon.png';
import card3TopIcon from '../images/card3-icon.png';
import card4TopIcon from '../images/card4-icon.png';
import card5TopIcon from '../images/card5-icon.png';

const OurApproach = () => {
  return (
    <div className="py-12 w-full text-white font-poppins"
      style={{
        background: 'linear-gradient(to bottom, #004E73,#2B6B89, white)',
      }}>
      <div className="container mx-auto px-4 text-center max-w-4xl">
        {/* Section Heading */}
        <h2 className="text-3xl md:text-4xl font-medium mb-4 font-poppins">Our Approach</h2>
        <h3 className="text-2xl font-poppins font-light mb-4">Science-Driven. AI-Powered. Accurate</h3>

        {/* Section Description */}
        <p className="mb-8 max-w-3xl mx-auto text-lg font-thin">
          Our <span className="font-bold">Vayuh AI Hail Risk Model</span> offers cutting-edge simulations that are <span className="font-semibold">100x faster</span> than traditional models, allowing insurers to run complex analyses on demand. The model uses high-resolution data, with a 3 sq.km grid updated every 6 hours, covering hail risk from <span className="font-semibold">1980 to 2027</span>. This level of detail provides granular insights into local and global hail risk trends. The scientific foundation of the model is built on advanced AI algorithms developed by leading climate scientists, with over 20,000 citations and a Nature publication, ensuring both accuracy and reliability.
        </p>

        {/* Center Icon */}
        <div className="flex justify-center mb-8 rounded-md md:h-64 shadow-2xl pb-4" style={{backgroundColor: "#E8F4F9"}}>
          <img src={centerIcon} alt="Center Icon" className="h-full hidden md:block" />
          <img src={centerIconMobile} alt="Center Icon" className="h-full md:hidden" />
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Card 1 */}
          <div className="bg-white text-gray-900 rounded-lg shadow-lg font-poppins w-64 mx-auto hover:shadow-2xl hover:shadow-yellow-100 transition duration-300 ease-in-out">
            <div className="relative h-40 bg-cover bg-center rounded-t-lg"
              style={{ backgroundImage: `url(${card1Icon})` }}>
              {/* Black opacity layer */}
              <div className="absolute inset-0 bg-black opacity-70 rounded-t-lg"></div>
              {/* Icon and title on top */}
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10">
                <img src={card1TopIcon} alt="Top Icon" className="w-12 h-12 mb-2" />
                <h4 className="text-lg font-normal px-2">High Correlation with Ground Truth</h4>
              </div>
            </div>
            <div className="p-6">
              <p className="text-sm">
                Our models are rigorously tested and have a high correlation with real-world data, ensuring reliability.
              </p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="bg-white text-gray-900 rounded-lg shadow-lg font-poppins w-64 mx-auto hover:shadow-2xl hover:shadow-yellow-100 transition duration-300 ease-in-out">
            <div className="relative h-40 bg-cover bg-center rounded-t-lg"
              style={{ backgroundImage: `url(${card2Icon})` }}>
              {/* Black opacity layer */}
              <div className="absolute inset-0 bg-black opacity-70 rounded-t-lg"></div>
              {/* Icon and title on top */}
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10">
                <img src={card2TopIcon} alt="Top Icon" className="w-12 h-12 mb-2" />
                <h4 className="text-lg font-normal px-2">Scalable Solutions from Local to Global</h4>
              </div>
            </div>
            <div className="p-6">
              <p className="text-sm">
                Whether you’re managing risk for a local region or on a global scale, our models adapt to your needs.
              </p>
            </div>
          </div>

          {/* Card 3 */}
          <div className="bg-white text-gray-900 rounded-lg shadow-lg font-poppins w-64 mx-auto hover:shadow-2xl hover:shadow-yellow-100 transition duration-300 ease-in-out">
            <div className="relative h-40 bg-cover bg-center rounded-t-lg"
              style={{ backgroundImage: `url(${card3Icon})` }}>
              {/* Black opacity layer */}
              <div className="absolute inset-0 bg-black opacity-70 rounded-t-lg"></div>
              {/* Icon and title on top */}
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10">
                <img src={card3TopIcon} alt="Top Icon" className="w-12 h-12 mb-2" />
                <h4 className="text-lg font-normal px-2">Built by Leading Climate Scientists</h4>
              </div>
            </div>
            <div className="p-6">
              <p className="text-sm">
                Developed by scientists at the forefront of climate change research with over 20,000 citations and a Nature paper on climate modeling using AI.
              </p>
            </div>
          </div>
          

          {/* Flex container to center the last two cards */}
          <div className="grid grid-cols-1 gap-8 mt-8 md:col-span-3 md:flex md:justify-center md:space-x-8 mx-auto">
            {/* Card 4 */}
            <div className="bg-white text-gray-900 rounded-lg shadow-lg font-poppins w-64 hover:shadow-2xl hover:shadow-yellow-100 transition duration-300 ease-in-out">
              <div className="relative h-40 bg-cover bg-center rounded-t-lg"
                style={{ backgroundImage: `url(${card4Icon})` }}>
                {/* Black opacity layer */}
                <div className="absolute inset-0 bg-black opacity-70 rounded-t-lg"></div>
                {/* Icon and title on top */}
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10">
                  <img src={card4TopIcon} alt="Top Icon" className="w-12 h-12 mb-2" />
                  <h4 className="text-lg font-normal px-2">Proven in Financial Markets</h4>
                </div>
              </div>
              <div className="p-6">
                <p className="text-sm">
                  Our models are trusted by financial institutions in the energy trading sector for accurate and timely risk assessment.
                </p>
              </div>
            </div>
            
            {/* Card 5 */}
            <div className="bg-white text-gray-900 rounded-lg shadow-lg font-poppins w-64 hover:shadow-2xl hover:shadow-yellow-100 transition duration-300 ease-in-out">
              <div className="relative h-40 bg-cover bg-center rounded-t-lg"
                style={{ backgroundImage: `url(${card5Icon})` }}>
                {/* Black opacity layer */}
                <div className="absolute inset-0 bg-black opacity-70 rounded-t-lg"></div>
                {/* Icon and title on top */}
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10">
                  <img src={card5TopIcon} alt="Top Icon" className="w-12 h-12 mb-2" />
                  <h4 className="text-lg font-normal px-2">Collaborations with Industry Leaders</h4>
                </div>
              </div>
              <div className="p-6">
                <p className="text-sm">
                  We collaborate with leading insurers, including Lloyd’s of London, to bring cutting-edge climate risk modeling to the insurance sector.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurApproach;
