import React from 'react';
import riskAssessmentImage from '../images/risk-assessment.jpg'; // Replace with the actual image path

const ExposureDataSection = () => {
  return (
    <div className="py-12 w-full text-center font-poppins">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between max-w-4xl">
        {/* Text on the left */}
        <div className="mb-6 md:mb-0 md:mr-8 w-1/2">
          <h2 className="text-xl md:text-3xl font-poppins font-normal mb-2 text-center md:text-left text-gray-700">
            Test Change with <br /> Exposure Data
          </h2>
        </div>

        {/* Image wrapped in a div with grey background and glow on hover */}
        <div className="bg-gray-200 p-4 rounded-lg w-3/4 shadow-2xl shadow-yellow-200 transition duration-300 ease-in-out">
          <img
            src={riskAssessmentImage}
            alt="Location Risk Assessment"
            className="rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default ExposureDataSection;
