import React, { useState } from 'react';
import HubspotForm from 'react-hubspot-form';

const HubspotModal = ({isOpen, closeModal}) => {
  
  return (
    <div>
      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg relative w-full max-w-lg mx-auto">
            {/* Close button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-black hover:text-red-600 text-xl font-bold"
            >
              &times;
            </button>

            {/* Modal Content - HubSpot Form */}
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <HubspotForm
              portalId="23115881"
              formId="1b20d979-5f4c-4ed7-9880-4a350f457767"
              onSubmit={() => console.log('Form submitted!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HubspotModal;
