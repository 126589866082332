import React from 'react';
import pattern1 from '../images/pattern1.png'; // Replace with the correct path for your pattern
import hyperlocalIcon from '../images/hyperlocal-icon.png'; // Replace with actual icon paths
import dataGapIcon from '../images/data-gap-icon.png';
import climateChangeIcon from '../images/climate-change-icon.png';
import arrowIcon from '../images/arrow-icon.png'; // For the arrow icon in the middle

const WhyTraditionalModelsFail = () => {
  return (
    <div className="relative py-12 w-full"
      style={{
        background: 'linear-gradient(to bottom, #F5F3EA, white)',
      }}
    >
       <div className="absolute bottom-0 right-0 h-1/2 w-1/4 ">
        <img src={pattern1} alt="Pattern" className="w-full h-full object-cover rotate-180" />
      </div>

      <div className="container mx-auto px-4 max-w-3xl">
        <div className="grid grid-cols-1 md:grid-cols-[45%,10%,45%] gap-8 items-center">
          
          {/* Left side with icons and descriptions */}
          <div className="space-y-8 text-center md:text-center order-3 md:order-1">
            <div className="flex flex-col items-center md:items-center">
              <img src={hyperlocalIcon} alt="Hyperlocal events" className="w-12 h-12 mb-4" />
              <p className="text-lg text-gray-800">Hyperlocal events are hard to model</p>
            </div>

            <div className="flex flex-col items-center md:items-center">
              <img src={dataGapIcon} alt="Data gaps" className="w-12 h-12 mb-4" />
              <p className="text-lg text-gray-800">Historical data has gaps and inaccuracies.</p>
            </div>

            <div className="flex flex-col items-center md:items-center">
              <img src={climateChangeIcon} alt="Climate change" className="w-12 h-12 mb-4" />
              <p className="text-lg text-gray-800">Climate change makes future trends harder to predict</p>
            </div>
          </div>

          {/* Middle section with arrow icon */}
          <div className="flex justify-center order-2">
            <img src={arrowIcon} alt="Arrow" className="w-28" />
          </div>

          {/* Right side with title */}
          <div className="text-center md:text-right order-1 md:order-3">
            <h2 className="text-3xl text-vayuh md:text-4xl font-normal text-gray-800 font-poppins">
              Why Traditional Models Fail?
            </h2>
          </div>

        </div>
      </div>
    </div>
  );
};

export default WhyTraditionalModelsFail;
