import React from 'react';

const DemoSection = ({demoForm}) => {
  return (
    <div className="py-12 w-full text-center font-poppins">
      <div className="container mx-auto px-4 max-w-4xl">
        {/* Heading */}
        <h2 className="text-3xl md:text-4xl font-medium mb-6">
          Ready to Revolutionize Your Hail Risk Management?
        </h2>

        {/* Button with Shine Effect */}
        <div className="inset-0 mb-12 flex justify-center items-center">
            <a
            href="/schedule-demo"
            className="relative text-black py-3 px-6 transition duration-300 font-poppins font-normal overflow-hidden shine-button"
            style={{
                background: 'linear-gradient(to right, #FFE693, #F0C22F)',
                borderRadius: '6px',
            }}
            onClick={(event)=> {event.preventDefault(); demoForm();}}
            >
            Schedule a Demo
            </a>
        </div>

        {/* Subtext */}
        <p className="text-gray-600 mb-8">
          See how our next-generation hail risk models can transform your business.
        </p>

        {/* Contact Information */}
        <p className="text-gray-800">
          or Contact <span className="font-bold">Dr. Mayur Mudigonda</span> -{' '}
          <a href="mailto:mayur.mudigonda@vayuh.ai" className="text-blue-600">
            mayur.mudigonda@vayuh.ai
          </a>{' '}
          to schedule a demo and test privately with your exposure data.
        </p>
      </div>
    </div>
  );
};

export default DemoSection;
