import React, { useState } from "react";
import HubspotForm from 'react-hubspot-form';
import vayuh_background from '../images/vayuh_background.webp';

const WorkshopForm = ({ className }) => {

  return (
    <div id="workshop" className={`flex flex-col items-center ${className}`} style={{ width: '100%' }}>
      <div className="content contact-form my-4">
        <h3 className="font_3">Register for the <br></br>Climate Workshop</h3>
        <HubspotForm
          portalId='23115881'
          formId='b0a7f9f3-e6db-4559-a26b-73b34343074e'
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
        {/* <h4 style={{color:"rgb(var(--color_42))", fontWeight: 350}}>Lookout for the invite to Vayuh + Lloyd's Lab Workshop on <span style={{fontWeight: 800}}>CLIMATE CHANGE AND AI TO TACKLE PERILS</span> from us shortly</h4> */}
      </div>
    </div>
  );
};

export default WorkshopForm;
